import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Container,
  Form,
  Label,
  Input,
  Button,
  Alert,
  Row,
  Col,
  Card,
  CardTitle,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import debounce from "lodash.debounce";
import api from "../Api";

const OrderForm = () => {
  const [formData, setFormData] = useState({
    symbol: "",
    coin: "",
    type: "market",
    side: "",
    size: 0,
    price: 0,
  });

  const [coins, setCoins] = useState([]);
  const [status, setStatus] = useState({
    response: null,
    error: null,
    balance: {},
    fromCoin: "",
    estValue: 0,
  });

  const [isPriceDisabled, setIsPriceDisabled] = useState(true);
  const exchangeInfoRef = useRef(null);

  useEffect(() => {
    fetchExchangeInfo();
    fetchBalance();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(updatePrice, 1000);
    return () => clearInterval(intervalId);
  }, [formData.symbol, formData.size, formData.type, formData.coin, status.fromCoin]);

  const fetchExchangeInfo = async () => {
    try {
      const res = await axios.get("https://api.binance.com/api/v3/exchangeInfo");
      exchangeInfoRef.current = res.data.symbols;
      const coinSet = new Set();
      exchangeInfoRef.current.forEach((pair) => {
        coinSet.add(pair.baseAsset);
        coinSet.add(pair.quoteAsset);
      });
      const coinOptions = Array.from(coinSet).map((coin) => ({
        value: coin,
        label: coin,
      }));
      setCoins(coinOptions);
    } catch (err) {
      console.error("Error fetching exchange info", err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    updateFormData(name, value);
    if (name === "coin" || name === "fromCoin") {
      if (name === "coin") {
        updateSymbol(status.fromCoin, value);
      } else {
        updateStatus("fromCoin", value);
        updateSymbol(value, formData.coin);
      }
    }
    if (name === "type") {
      setIsPriceDisabled(value === "market");
    }
    if (name === "size" || name === "type" || name === "symbol" || name === "coin" || name === "fromCoin") {
      debouncedUpdatePrice();
    }
  };

  const updateFormData = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const updateStatus = (name, value) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [name]: value,
    }));
  };

  const updateSymbol = (fromCoin, toCoin) => {
    if (!fromCoin || !toCoin) return;
    const symbol = exchangeInfoRef.current.find(
      (pair) =>
        (pair.baseAsset === fromCoin && pair.quoteAsset === toCoin) ||
        (pair.baseAsset === toCoin && pair.quoteAsset === fromCoin)
    )?.symbol;
    if (symbol) {
      updateFormData("symbol", symbol);
    } else {
      updateFormData("symbol", '');
    }
  };

  const updatePrice = async () => {
    if (!formData.symbol) {
      updateFormData("price", 0);
      return;
    }

    try {
      const res = await axios.get(
        `https://api.binance.com/api/v3/ticker/price?symbol=${formData.symbol}`
      );
      const price = parseFloat(res.data.price);
      const isCoinBaseAsset = formData.symbol.startsWith(formData.coin);

      if (formData.type === "market") {
        setFormData((prevData) => ({
          ...prevData,
          side: isCoinBaseAsset ? "long" : "short",
          price: price,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          side: isCoinBaseAsset ? "long" : "short",
        }));
      }

      const eValue =
        formData.side === "short"
          ? formData.size * price
          : formData.size / price;
      updateStatus("estValue", eValue);
    } catch (err) {
      console.error("Error fetching price", err);
    }
  };

  const debouncedUpdatePrice = useCallback(debounce(updatePrice, 500), [
    formData.symbol,
    formData.size,
    formData.type,
    formData.coin,
    status.fromCoin,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation logic
    if (!formData.symbol) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        error: "Symbol is required",
      }));
      return;
    }
    if (!formData.coin) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        error: "Coin is required",
      }));
      return;
    }
    if (!formData.side) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        error: "Side is required",
      }));
      return;
    }
    if (!formData.type) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        error: "Order type is required",
      }));
      return;
    }
    if (formData.size <= 0) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        error: "Size must be greater than 0",
      }));
      return;
    }
    if (formData.type === "limit" && formData.price <= 0) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        error: "Price must be greater than 0 for limit orders",
      }));
      return;
    }

    const reqData = new FormData();
    reqData.append("symbol", formData.symbol);
    reqData.append("coin", formData.coin);
    reqData.append("side", formData.side);
    reqData.append("type", formData.type);
    reqData.append("size", formData.size);
    reqData.append("price", formData.price);

    try {
      const result = await api.post("/api/order_create/", reqData);
      const response = result.data;
      console.log(response);
      setStatus((prevStatus) => ({
        ...prevStatus,
        response:
          response.status === "ok" && !response.order.msg
            ? "Order Created Successfully!"
            : null,
        error:
          response.status === "ok" && response.order.msg
            ? response.order.msg
            : response.message,
      }));
      fetchBalance();
    } catch (err) {
      setStatus((prevStatus) => ({
        ...prevStatus,
        response: null,
        error: err.response ? err.response.data : "Error occurred",
      }));
    }
  };

  const fetchBalance = async () => {
    try {
      const res = await api.get("/api/balance");
      if (res.data.status === "ok") {
        const filteredBalance = Object.keys(res.data.balance).reduce((acc, coin) => {
          if (res.data.balance[coin].free !== 0) {
            acc[coin] = res.data.balance[coin];
          }
          return acc;
        }, {});
        setStatus((prevStatus) => ({
          ...prevStatus,
          balance: filteredBalance,
        }));
      } else {
        setStatus((prevStatus) => ({
          ...prevStatus,
          balance: {},
        }));
      }
    } catch (err) {
      console.error("Error fetching balance", err);
      setStatus((prevStatus) => ({
        ...prevStatus,
        balance: {},
      }));
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg="8" md="10">
          <h2 className="text-center mt-4">
            SWAP <i className="fas fa-shopping-cart"></i><br></br><small>(min. $5)</small>
          </h2>
          <Form onSubmit={handleSubmit}>
            <div className="d-flex flex-column flex-md-row mb-3">
              <div className="flex-grow-1 flex-shrink-1 flex-basis-0 pr-3">
                <Card body>
                  <CardTitle tag="h5" for="fromCoin">
                    From Coin
                  </CardTitle>
                  <Input
                    className="mb-3"
                    type="select"
                    name="fromCoin"
                    id="fromCoin"
                    placeholder="Select a coin"
                    value={status.fromCoin}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Select a coin
                    </option>
                    {Object.keys(status.balance).map((coin) => (
                      <option key={coin} value={coin}>
                        {coin} (Free: {status.balance[coin]?.free})
                      </option>
                    ))}
                  </Input>
                  {formData.symbol.startsWith(formData.coin) ? <Label>Size in {formData.coin}</Label> : <Label>Size in {status.fromCoin}</Label>}
                  <Input
                    type="number"
                    name="size"
                    id="size"
                    value={formData.size}
                    onChange={handleChange}
                    min="0"
                    step="0.00001"
                  />
                </Card>
              </div>
              <div className="d-flex align-items-center justify-content-center flex-grow-1 flex-shrink-1 flex-basis-0 p-3">
                <i className="fa fa-exchange" aria-hidden="true"></i>
              </div>
              <div className="flex-grow-1 flex-shrink-1 flex-basis-0 pl-3">
                <Card body>
                  <CardTitle tag="h5" for="coin">
                    To Coin
                  </CardTitle>
                  <Select className="mb-3"
                    name="coin"
                    id="coin"
                    options={coins}
                    value={coins.find((option) => option.value === formData.coin)}
                    onChange={(option) =>
                      handleChange({ name: "coin", value: option.value })
                    }
                    isClearable
                    placeholder="Select a coin"
                    styles={{
                      option: (provided) => ({
                        ...provided,
                        color: "black",
                      }),
                    }}
                  />
                  <Label for="type">Order Type</Label>
                  <Input
                    type="select"
                    name="type"
                    id="type"
                    required
                    value={formData.type}
                    onChange={handleChange}
                    className="mb-3"
                  >
                    <option value="" disabled>
                      Select a type
                    </option>
                    <option value="market">Market</option>
                    <option value="limit">Limit</option>
                  </Input>
                  <Label for="price">Price {formData.symbol}</Label>
                  <Input
                    type="number"
                    name="price"
                    id="price"
                    placeholder="0"
                    value={formData.price}
                    onChange={handleChange}
                    disabled={isPriceDisabled}
                    min="0"
                    step="0.00001"
                  />
                </Card>
              </div>
            </div>
            <Button type="submit" color="primary" block>
              Create Order <i className="fas fa-shopping-cart"></i>
            </Button>
          </Form>
          <br />
          {status.response && <Alert color="success">{status.response}</Alert>}
          {status.error && <Alert color="danger">{status.error}</Alert>}
        </Col>
      </Row>
    </Container>
  );
};

export default OrderForm;
