import React, { useState, useEffect } from "react";
import api from "../Api";
import { Container, Row, Col, FormGroup, Label, Input, Button, Alert } from "reactstrap";

const Withdraw = () => {
    const [balance, setBalance] = useState({});
    const [balanceError, setBalanceError] = useState("");

    const [withdrawCoin, setWithdrawCoin] = useState("");
    const [withdrawAmount, setWithdrawAmount] = useState("");
    const [withdrawAddress, setWithdrawAddress] = useState("");
    const [withdrawResponse, setWithdrawResponse] = useState(null);
    const [withdrawError, setWithdrawError] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            await fetchBalance();
        };

        fetchData();
    }, []);

    const fetchBalance = async () => {
        try {
            const res = await api.get("/api/balance");
            if (res.data.status === "ok") {
                const filteredBalance = Object.keys(res.data.balance).reduce((acc, coin) => {
                    if (res.data.balance[coin].free !== 0) {
                        acc[coin] = res.data.balance[coin];
                    }
                    return acc;
                }, {});
                setBalance(filteredBalance);
                setBalanceError("");
            } else {
                setBalanceError(res.data.message);
            }
        } catch (error) {
            setBalanceError(error.response ? error.response.data.message : "Error fetching balance");
        }
    };    

    const handleWithdraw = async () => {
        try {
            const formData = new FormData();
            formData.append("coin", withdrawCoin);
            formData.append("size", parseFloat(withdrawAmount));
            formData.append("address", withdrawAddress);
            const res = await api.post("/api/withdraw/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (res.data.status === "ok" && !res.data.withdraw.msg) {
                setWithdrawResponse(res.data);
                setWithdrawError(null);
                await fetchBalance();
            } else if (res.data.status === "ok" && res.data.withdraw.msg) {
                setWithdrawResponse(null);
                setWithdrawError(res.data.withdraw.msg);
            } else {
                setWithdrawError(res.data.message);
            }
        } catch (error) {
            setWithdrawError(error.response ? error.response.data.message : "Error processing withdrawal");
            setWithdrawResponse(null);
        }
    };

    return (
        <Container>
            <Row className="justify-content-center">
                <Col lg="6" md="8">
                    <h2 className="text-center mt-4">WITHDRAW <i className="fas fa-money-bill-wave"></i><br></br><small>(min. $5)</small></h2>
                    
                    {balanceError && <Alert color="danger">{balanceError}</Alert>}
                    <FormGroup>
                        <Label for="withdrawCoin">Coin</Label>
                        <Input
                            type="select"
                            name="withdrawCoin"
                            id="withdrawCoin"
                            value={withdrawCoin}
                            required
                            onChange={(e) => setWithdrawCoin(e.target.value)}
                        >
                            <option value="" disabled>
                                Select a coin
                            </option>
                            {Object.keys(balance).map((coin) => (
                                <option key={coin} value={coin}>
                                    {coin} (Free: {balance[coin].free})
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="withdrawAmount">Amount</Label>
                        <Input
                            type="number"
                            name="withdrawAmount"
                            id="withdrawAmount"
                            value={withdrawAmount}
                            min="0"
                            required
                            onChange={(e) => setWithdrawAmount(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="withdrawAddress">Recipient Address (BEP20)</Label>
                        <Input
                            type="text"
                            name="withdrawAddress"
                            id="withdrawAddress"
                            value={withdrawAddress}
                            required
                            onChange={(e) => setWithdrawAddress(e.target.value)}
                        />
                    </FormGroup>
                    <Button color="primary" onClick={handleWithdraw} block>
                        Withdraw
                    </Button>
                    {withdrawResponse && (
                        <div className="mt-4">
                            {withdrawResponse.status === "ok" && (
                                <Alert color="success">
                                    <p>Withdrawal Request ID: {withdrawResponse.request_id}</p>
                                    <p>Coin: {withdrawResponse.params.coin}</p>
                                    <p>Amount: {withdrawResponse.params.size}</p>
                                    <p>Address: {withdrawResponse.params.address}</p>
                                    <p>Withdrawal ID: {withdrawResponse.withdraw.id}</p>
                                </Alert>
                            )}
                            {withdrawResponse.status === "error" && <Alert color="danger">{withdrawResponse.message}</Alert>}
                        </div>
                    )}
                    {withdrawError && <Alert className="mt-4" color="danger">{withdrawError}</Alert>}
                </Col>
            </Row>
        </Container>
    );
};

export default Withdraw;
