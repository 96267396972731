// src/Api.js
import axios from 'axios';

// Создаем экземпляр Axios с общими настройками
const api = axios.create({
    baseURL: 'https://bazer.site', // Укажите ваш базовый URL
    withCredentials: true, // Включаем передачу куки
    cors: true
});

export default api;
