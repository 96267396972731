import React, { useState, useEffect } from "react";
import api from "../Api";
import { Container, Row, Col, FormGroup, Label, Input, Button, Alert, InputGroup, InputGroupText } from "reactstrap";

const Deposit = () => {
    const [txId, setTxId] = useState("");
    const [response, setResponse] = useState(null);
    const [error, setError] = useState("");

    const handleCheckDeposit = async () => {
        try {
            const res = await api.get(`/api/getTransfer?txid=${txId}`);
            if (res.data.status === "ok") {
                setResponse(res.data);
                setError("");
            } else {
                setError(res.data.message);
                setResponse(null);
            }
        } catch (error) {
            setError(error.response ? error.response.data.message : "Error checking deposit");
            setResponse(null);
        }
    };

    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText('0x0b366a1ef0580e393b869e82bf37945495657756');
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Reset the state after 2 seconds
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    return (
        <Container>
            <Row className="justify-content-center">
                <Col lg="6" md="8">
                    <h2 className="text-center mt-4">DEPOSIT <i className="fas fa-money-bill-alt"></i><br></br><small>(min. $10)</small></h2>
                    
                    <FormGroup>
                        <Label for="billingAddress">Billing Address (BEP20 USDT/BUSD/USDC)</Label>
                        <InputGroup>
                            <Input
                                type="text"
                                name="billingAddress"
                                id="billingAddress"
                                value="0x0b366a1ef0580e393b869e82bf37945495657756"
                                readOnly
                            />
                            <InputGroupText>
                                <Button color="primary" onClick={handleCopy}>
                                    {isCopied ? <i className="fas fa-check"></i> : <i className="fas fa-copy"></i>}
                                </Button>
                            </InputGroupText>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup>
                        <Label for="txId">Transaction ID</Label>
                        <Input
                            type="text"
                            name="txId"
                            id="txId"
                            value={txId}
                            onChange={(e) => setTxId(e.target.value)}
                            required
                        />
                    </FormGroup>
                    <Button color="primary" onClick={handleCheckDeposit} block>
                        Check Deposit
                    </Button>
                    {response && (
                        <div className="mt-4">
                            {response.status === "ok" && response.transfer && (
                                <Alert color="success">
                                    <p>Transaction ID: {response.transfer.txId}</p>
                                    <p>Amount: {response.transfer.amount} {response.transfer.coin}</p>
                                    <p>Status: {response.transfer.status === 1 ? "Confirmed" : "Unconfirmed"}</p>
                                </Alert>
                            )}
                            {response.status === "error" && <Alert color="danger">{response.message}</Alert>}
                        </div>
                    )}
                    {error && <Alert className="mt-4" color="danger">{error}</Alert>}
                </Col>
            </Row>
        </Container>
    );
};

export default Deposit;
