import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import api from './Api';
import Login from './components/Login';
import Register from './components/Register';
import Balance from './components/Balance';
import Swap from './components/OrderForm';
import Orders from './components/Orders';
import Deposit from './components/Deposit';
import Withdraw from './components/Withdraw';
import UserInfo from './components/UserInfo';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkAuth() {
      try {
        const response = await api.get('/api/userInfo');
        if (response.data.status === 'ok') {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    }

    checkAuth();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div>
        <nav className="navbar navbar-expand navbar-light bg-dark" data-bs-theme="dark">
          <div className="container">
            <Link className="navbar-brand" to="/">BROKER</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                {isAuthenticated ? (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link" to="/bal">
                        <i className="fas fa-briefcase"></i>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/dep">
                        <i className="fas fa-money-bill-alt"></i>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/with">
                        <i className="fas fa-money-bill-wave"></i>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/ord">
                        <i className="fas fa-shopping-cart"></i>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/ords">
                        <i className="fas fa-list"></i>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/user">
                        <i className="fas fa-user"></i>
                      </Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link" to="/auth">
                        <i className="fas fa-sign-in-alt"></i>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/reg">
                        <i className="fas fa-user-plus"></i>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </nav>

        <div className="container mt-5 pt-3 text-break">
          <Routes>
            <Route path="/auth" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/reg" element={<Register />} />
            <Route path="/bal" element={<Balance />} />
            <Route path="/ord" element={<Swap />} />
            <Route path="/ords" element={<Orders />} />
            <Route path="/dep" element={<Deposit />} />
            <Route path="/with" element={<Withdraw />} />
            <Route path="/user" element={<UserInfo setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/" element={isAuthenticated ? <Navigate to="/bal" /> : <Navigate to="/auth" />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
