import React, { useState, useEffect } from "react";
import api from "../Api";
import { Container, Table, Alert, Row, Col } from "reactstrap";

const Balance = () => {
    const [balance, setBalance] = useState(null);
    const [error, setError] = useState("");

    useEffect(() => {
        async function fetchBalance() {
            try {
                const response = await api.get("/api/balance");
                if (response.data.status === "ok") {
                    if (response.data.balance && Object.keys(response.data.balance).length !== 0) {
                        const filteredBalance = Object.keys(response.data.balance).reduce((acc, currency) => {
                            if (response.data.balance[currency].free !== 0 || response.data.balance[currency].locked !== 0) {
                                acc[currency] = response.data.balance[currency];
                            }
                            return acc;
                        }, {});
                        setBalance(filteredBalance);
                    } else {
                        setBalance({
                            USDT: { free: 0, locked: 0 },
                            BTC: { free: 0, locked: 0 },
                            ETH: { free: 0, locked: 0 },
                        });
                    }
                } else {
                    setError(response.data.message || "Неизвестная ошибка.");
                }
            } catch (error) {
                setError(error.response ||  "Ошибка при получении баланса.");
            }
        }

        fetchBalance();
    }, []);

    return (
        <Container>
            <Row className="justify-content-center">
                <Col lg="6" md="8">
                    <h2 className="text-center mt-4">
                        BALANCE <i className="fas fa-briefcase"></i>
                    </h2>
                    {error && <Alert color="danger">{error}</Alert>}
                    {balance && Object.keys(balance).length > 0 ? (
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>
                                        Currency <i className="fas fa-coins"></i>
                                    </th>
                                    <th>
                                        Free <i className="fas fa-hand-holding-usd"></i>
                                    </th>
                                    <th>
                                        Locked <i className="fas fa-lock"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(balance).map((currency) => (
                                    <tr key={currency}>
                                        <td>{currency}</td>
                                        <td>{balance[currency].free}</td>
                                        <td>{balance[currency].locked}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        !error && <p>Loading...</p>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default Balance;