import React, { useState, useEffect } from "react";
import api from "../Api";
import { Container, Table, Alert, Row, Col, Button, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import axios from "axios";

const Orders = () => {
    const [openOrders, setOpenOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [symbol, setSymbol] = useState("");
    const [symbols, setSymbols] = useState([]);
    const [showOrderDetails, setShowOrderDetails] = useState(false);

    useEffect(() => {
        async function fetchSymbols() {
            try {
                const response = await axios.get("https://api.binance.com/api/v3/exchangeInfo");
                const pairs = response.data.symbols.map(pair => ({
                    value: pair.symbol,
                    label: pair.symbol
                }));
                setSymbols(pairs);
            } catch (error) {
                setError("Ошибка при получении символов.");
                console.error(error);
            }
        }

        fetchSymbols();
    }, []);

    useEffect(() => {
        async function fetchOpenOrders() {
            try {
                const response = await api.get(`/api/open_orders?symbol=${symbol}`);
                if (response.data.status === "ok") {
                    setOpenOrders(response.data.orders);
                } else {
                    setError(response.data.message || "Неизвестная ошибка при получении открытых ордеров.");
                }
            } catch (error) {
                setError(error.response || "Ошибка при получении открытых ордеров.");
            }
        }

        fetchOpenOrders();
    }, [symbol]);

    const handleShowOrder = async (orderId) => {
        try {
            const response = await api.get(`/api/get_order?symbol=${symbol}&order_id=${orderId}`);
            if (response.data.status === "ok") {
                setSelectedOrder(response.data.order);
                setShowOrderDetails(true);
            } else {
                setError(response.data.message || "Не удалось получить детали ордера.");
            }
        } catch (error) {
            setError(error.response || "Ошибка при получении деталей ордера.");
        }
    };

    const handleHideOrderDetails = () => {
        setShowOrderDetails(false);
    };

    const handleDeleteOrder = async (orderId) => {
        try {
            const formData = new FormData();
            formData.append("symbol", symbol);
            formData.append("order_id", orderId);
            const response = await api.post("/api/order_cancel/", formData);
            if (response.data.status === "ok") {
                setSuccessMessage("Ордер успешно удален.");
                setOpenOrders(openOrders.filter(order => order.orderId !== orderId));
            } else {
                setError(response.data.message || "Не удалось отменить ордер.");
            }
        } catch (error) {
            setError(error.response || "Ошибка при отмене ордера.");
        }
    };

    return (
        <Container>
            <Row className="justify-content-center">
                <Col lg="6" md="8">
                    <h2 className="text-center mt-4">ORDERS <i className="fas fa-list"></i></h2>
                    {error && <Alert color="danger">{error}</Alert>}
                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                    <FormGroup>
                        <Label for="symbol">Symbol</Label>
                        <Select
                            name="symbol"
                            id="symbol"
                            options={symbols}
                            value={symbols.find(option => option.value === symbol)}
                            onChange={(selectedOption) => setSymbol(selectedOption.value)}
                            isClearable
                            placeholder="Select a symbol"
                            styles={{
                                option: (provided) => ({
                                    ...provided,
                                    color: 'black',
                                }),
                            }}
                        />
                    </FormGroup>
                    {openOrders.length > 0 ? (
                        <div style={{ overflowX: 'auto' }}>
                            <Table striped className="table-nowrap">
                                <thead>
                                    <tr>
                                        <th><i className="fas fa-hashtag"></i></th>
                                        <th><i className="fas fa-exchange-alt"></i></th>
                                        <th><i className="fas fa-hand-paper"></i></th>
                                        <th><i className="fas fa-dollar-sign"></i></th>
                                        <th><i className="fas fa-coins"></i></th>
                                        <th><i className="fas fa-info-circle"></i></th>
                                        <th><i className="fas fa-cog"></i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {openOrders.map(order => (
                                        <tr key={order.orderId}>
                                            <td>{order.orderId}</td>
                                            <td>{order.side}</td>
                                            <td>{order.type}</td>
                                            <td>{order.price}</td>
                                            <td>{order.origQty}</td>
                                            <td>{order.status}</td>
                                            <td>
                                                <Button color="primary" size="sm" onClick={() => handleShowOrder(order.orderId)}>Show</Button>
                                                {' '}
                                                <Button color="danger" size="sm" onClick={() => handleDeleteOrder(order.orderId)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    ) : (
                        <p>No open orders.</p>
                    )}
                    {showOrderDetails && selectedOrder && (
                        <div className="mt-4">
                            <h4>Order Details</h4>
                            <div style={{ overflowX: 'auto' }}>
                                <Table striped className="table-nowrap">
                                    <thead>
                                        <tr>
                                            <th><i className="fas fa-hashtag"></i></th>
                                            <th><i className="fas fa-sync"></i></th>
                                            <th><i className="fas fa-exchange-alt"></i></th>
                                            <th><i className="fas fa-hand-paper"></i></th>
                                            <th><i className="fas fa-dollar-sign"></i></th>
                                            <th><i className="fas fa-coins"></i></th>
                                            <th><i className="fas fa-info-circle"></i></th>
                                            <th><i className="fas fa-clock"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{selectedOrder.orderId}</td>
                                            <td>{selectedOrder.symbol}</td>
                                            <td>{selectedOrder.side}</td>
                                            <td>{selectedOrder.type}</td>
                                            <td>{selectedOrder.price}</td>
                                            <td>{selectedOrder.origQty}</td>
                                            <td>{selectedOrder.status}</td>
                                            <td>{new Date(selectedOrder.time).toLocaleString()}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <Button color="secondary" onClick={handleHideOrderDetails}>Hide</Button>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default Orders;
