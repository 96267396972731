import React, { useState, useEffect } from "react";
import api from "../Api";
import { Container, Button, Alert, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";

function UserInfo({ setIsAuthenticated }) {
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchUserInfo() {
            try {
                const response = await api.get("/api/userInfo");
                if (response.data.status === "ok") {
                    setUserInfo(response.data);
                } else {
                    setError(response.data.message);
                }
            } catch (error) {
                setError(error.response ||  "Ошибка при получении информации о пользователе.");
            }
        }

        fetchUserInfo();
    }, []);

    const handleLogout = () => {
        api.get("/logout");
        setUserInfo(null);

        navigate("/");
        setIsAuthenticated(false);
    };

    return (
        <Container>
            <Row className="justify-content-center">
                <Col lg="4" md="6">
                    <h2 className="text-center mt-4">
                        USER <i className="fas fa-user"></i>
                    </h2>
                    {error && <Alert color="danger">{error}</Alert>}
                    {userInfo ? (
                        <div>
                            <p>
                                <strong>Username:</strong> {userInfo.username}
                            </p>
                            <p>
                                <strong>Email:</strong> {userInfo.email}
                            </p>
                            <Button color="primary" onClick={handleLogout} block>
                                Logout <i className="fas fa-sign-out-alt"></i>
                            </Button>
                        </div>
                    ) : (
                        !error && <p>Loading...</p>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default UserInfo;
