import React, { useState } from "react";
import api from "../Api";
import {
    Container,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Alert,
    Row,
    Col,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

function Register() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError("Пароли не совпадают.");
            return;
        }

        const formData = new FormData();
        formData.append("username", username);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("password2", confirmPassword);

        try {
            const response = await api.post("/register/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data.status === "ok") {
                navigate("/auth");
            } else {
                setError(response.data.error);
            }
        } catch (error) {
            setError(error.response ||  "Ошибка при отправке запроса.");
        }
    };

    return (
        <Container>
            <Row className="justify-content-center">
                <Col lg="4" md="6">
                    <h2 className="text-center mt-4">
                        Register <i className="fas fa-user-plus"></i>
                    </h2>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="username">Username</Label>
                            <Input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="confirmPassword">Confirm Password</Label>
                            <Input
                                type="password"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <Button color="primary" type="submit" block>
                            Register <i className="fas fa-user-plus"></i>
                        </Button>
                        {error && (
                            <Alert color="danger" className="mt-3">
                                {error}
                            </Alert>
                        )}
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Register;
